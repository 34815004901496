import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Router, RouterLink } from '@angular/router';
import { catchError, of } from 'rxjs';
import { ItemCardComponent } from 'src/app/components/item-card/item-card.component';
import { TypesBarComponent } from 'src/app/components/types-bar/types-bar.component';
import { ContentService } from 'src/app/services/content/content.service';
import { Subscribed } from 'src/utility-types/subscribed';
import { AuthState } from '../auth/auth.state';
import { META_DEFAULTS } from '../subpage/subpage.meta-const';

export const resolveHome = {
  content: (route: ActivatedRouteSnapshot) => {
    const router = inject(Router);
    const contentService = inject(ContentService);
    const meta = inject(Meta);
    const title = inject(Title);

    const metaObj = route.params['category']
      ? (META_DEFAULTS as any)[route.params['category']]
      : META_DEFAULTS['home'];

    title.setTitle(metaObj.title);
    meta.addTags([
      {
        name: 'keywords',
        content: metaObj.keywords?.join(', '),
      },
      {
        name: 'description',
        content: metaObj.description,
      },
    ]);

    return contentService.getHome(route.params['category']).pipe(
      catchError(err => {
        console.error('home err: ', err);
        router.navigate(['/404']);

        return of(null);
      })
    );
  },
};

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    MatIconModule,
    ItemCardComponent,
    NgOptimizedImage,
    RouterLink,
    MatButtonModule,
    TypesBarComponent,
  ],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export default class HomeComponent {
  private authState = inject(AuthState);

  @Input() category?: string;
  @Input() content!: Subscribed<ReturnType<ContentService['getHome']>>;

  $isLoggedIn = this.authState.$isLoggedIn;
}
