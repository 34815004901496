<app-types-bar [category]="category" />

@if (content) {
  @if (!category && !$isLoggedIn()) {
    <div class="header-wrap">
      <header>
        <img
          class="logo"
          fill
          priority="priority"
          loading="priority"
          src="/assets/phantom-logo-512.png"
          alt="Phantomlib logo" />

        <h1 class="mat-headline-1">Unlock the top-tier online resources</h1>
        <h2 class="mat-headline-4">
          A curated goldmine of resources for designers, developers and
          marketers. Handpicked and selected by of the absolute best.
        </h2>

        <div class="benefits">
          <div class="benefit">
            <img
              width="24px"
              src="/assets/icons/checkmark.svg"
              alt="checkmark icon" />
            Discover the best ressources
          </div>

          <div class="benefit">
            <img
              width="24px"
              src="/assets/icons/checkmark.svg"
              alt="checkmark icon" />
            Save your favorites in your own library
          </div>

          <div class="benefit">
            <img
              width="24px"
              src="/assets/icons/checkmark.svg"
              alt="checkmark icon" />
            Post stuff & get noticed
          </div>
        </div>
        <button
          class="large"
          mat-flat-button
          color="primary"
          routerLink="/app/auth/register">
          Create a free account
        </button>
      </header>
    </div>
  }

  <section class="category">
    <div class="header-bar">
      <h3 class="mat-headline-3 transition-page-title">Products</h3>
      <a class="mat-button" routerLink="/type/products">Browse all</a>
    </div>

    <div class="cards">
      @for (product of content.products; track product._id; let i = $index) {
        <app-item-card
          [imageLoadingStrategy]="i === 0 ? 'priority' : 'auto'"
          [content]="product" />
      }
    </div>
  </section>

  <section class="category">
    <div class="header-bar">
      <h3 class="mat-headline-3 transition-page-title">Podcasts</h3>
      <a class="mat-button" routerLink="/type/podcasts">Browse all</a>
    </div>

    <div class="cards col-6">
      @for (podcast of content.podcasts; track podcast._id; let i = $index) {
        <app-item-card [showAvatar]="true" [content]="podcast" />
      }
    </div>
  </section>

  <section class="category">
    <div class="header-bar">
      <h3 class="mat-headline-3 transition-page-title">Videos</h3>
      <a class="mat-button" routerLink="/type/videoes">Browse all</a>
    </div>

    <div class="cards">
      @for (video of content.videos; track video._id; let i = $index) {
        <app-item-card [content]="video" />
      }
    </div>
  </section>
}
